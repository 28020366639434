
import { Vue, Options } from 'vue-class-component';
import CopdPatientsTable from '@/lib/components/Programs/CopdProgram/CopdPatientsTable.vue';
import BasePagination from '@/lib/components/Pagination/BasePagination.vue';
import { CopdProgramService, TagGroupService } from '@/services/api';
import { FilterStorageService } from '@/services';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import {
  CopdProgram,
  CopdProgramMeasurement,
  CopdProgramReview,
  Organisation,
  PaginatedResponse,
  Patient,
  Tag,
  TagFilter,
  TagGroup,
  TagGroupApiFormat,
  TagGroupFilter
} from '@/models';
import BaseTextInput from '@/lib/components/Input/BaseTextInput.vue';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import { FilterGroup } from '@/lib/components/Filter';
import debounce from 'lodash-es/debounce';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import { useProgressStore } from '@/stores/progress.store';
import { useNotificationStore } from '@/stores/notification.store';
import { useSessionStore } from '@/stores/session.store';

@Options({
  components: {
    BasePagination,
    BaseTextInput,
    ButtonLink,
    CopdPatientsTable,
    FilterGroup
  },
  props: {
    organisationId: {
      type: String,
      required: true
    },
    sort: {
      type: String,
      default: 'last_sent'
    }
  }
})
export default class ProgramListPage extends Vue {
  progressStore = useProgressStore();
  notificationStore = useNotificationStore();
  sessionStore = useSessionStore();
  copdProgramService = new CopdProgramService();
  loading = true;
  request: CancelTokenSource | null = null;
  perPage = 0;
  total = 0;
  sort!: string;
  organisationId!: string;
  filtered = false;
  search = '';
  unWatchRoute = null;
  rows: CopdProgram[] = [];
  tags: Array<Tag> = [];
  tagGroupResources: Array<TagGroup> = [];
  patients: Patient[] = [];
  reviews: CopdProgramReview[] = [];
  organisationalUnits: Organisation[] = [];
  measurements: CopdProgramMeasurement[] = [];
  tagGroupService = new TagGroupService();
  tagGroups: TagGroupFilter[] = [];
  preselectedTagGroupsLoaded = false;
  preselectedTagGroups: TagGroupFilter[] = [];
  filteredTags: { [key: string]: string } = {};

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateURL(), 500);
  }

  get tagsFeatureEnabled(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }

  get pageHeading(): string {
    return this.filtered ? this.$t('platform.common.search-results') : this.$t('custom.uhb.copd.copd-esd-pathway-episodes');
  }

  mounted() {
    this.$watch('organisationId', async () => {
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      this.search = String(this.$route.query.search || '');
      await this.fetchPrograms();
    });

    this.unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchPrograms();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  async created() {
    this.progressStore.startProgress();
    await this.getTagGroups();
    this.preselectedTagGroupsLoaded = true;
    await this.fetchPrograms();
  }

  /**
   * fetch filter params from url and stores it in local storage
   * if filter only exist in localstorage, adds it to url and uses it as filter.
   */
  async getTagGroups() {
    if (isFeatureFlagEnabled(FEATURES.TAGS)) {
      await this.fetchTags();
      this.tagGroups.forEach((group) => {
        if (this.$route.query[`filter[${group.name}]`]) {
          this.filteredTags[`filter[${group.name}]`] = this.$route.query[`filter[${group.name}]`];
        }
      });
      if (Object.keys(this.filteredTags).length > 0) {
        this.setTagGroups();
      } else if (FilterStorageService.exists(FilterStorageService.VIRTUAL_WARD)) {
        await this.getTagGroupFromLocalStorage();
      }
    }
  }

  async getTagGroupFromLocalStorage() {
    this.preselectedTagGroups = JSON.parse(FilterStorageService.get(FilterStorageService.VIRTUAL_WARD));
    const tags = {};
    this.preselectedTagGroups.map((tagGroup) => {
      tags[tagGroup.id] = [];
      tagGroup.tags.map((tag) => {
        tags[tagGroup.id].push(tag);
      });
    });
    await this.filterTags(tags, false);
  }

  setTagGroups() {
    const tagIds: Array<string> = Object.values(this.filteredTags).flatMap((t) => t.split(','));
    this.preselectedTagGroups = this.tagGroups
      .filter((tagGroup: TagGroupFilter) => (
        tagGroup.tags.some((tag: TagFilter) => tagIds.includes(tag.id))
      ))
      .map((tagGroup: TagGroupFilter) => (
        {
          id: tagGroup.id,
          name: tagGroup.name,
          tags: tagGroup.tags.filter((tag: TagFilter) => tagIds.includes(tag.id))
        }
      ));
    FilterStorageService.set(FilterStorageService.VIRTUAL_WARD, JSON.stringify(this.preselectedTagGroups));
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
    if (this.unWatchRoute) {
      this.unWatchRoute();
    }
  }

  async fetchTags() {
    this.loading = true;
    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include: 'tags',
        'filter[type]': 'copd-pathway',
        per_page: 25
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.tagGroupService.index(requestConfig));

      response.data
        .reduce((tagsByGroupName: Map<string, Array<Tag>>, tagGroup: TagGroupApiFormat) => {
          const tagGroupName: string | undefined = tagGroup.attributes?.name;
          if (!tagGroupName) {
            return tagsByGroupName;
          }
          const existingTagsForGroupName: Array<Tag> | undefined = tagsByGroupName.get(tagGroupName);
          const newTagsForGroupName: Array<Tag> | undefined = tagGroup.relationships?.tags;
          if (!newTagsForGroupName) {
            return tagsByGroupName;
          }
          if (existingTagsForGroupName) {
            tagsByGroupName.set(tagGroupName, existingTagsForGroupName.concat(newTagsForGroupName));
          } else {
            tagsByGroupName.set(tagGroupName, newTagsForGroupName);
          }
          return tagsByGroupName;
        }, new Map())
        .forEach((tags: Array<Tag>, tagGroupName: string) => {
          const group: TagGroupFilter = {
            id: tagGroupName,
            name: tagGroupName,
            tags: []
          };
          tags.map((tag: Tag) => {
            const tagFilter: TagFilter = {
              id: tag.id,
              label: tag?.attributes?.name || ''
            };
            group.tags.push(tagFilter);
          });
          this.tagGroups.push(group);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async filterTags(tags, updateLocalStorage = true) {
    const filteredArray = Object.keys(tags).map((group) =>
      [`filter[${group}]`, tags[group].map((t) => t.id ? t.id : t.value).join(',')]);
    this.filteredTags = Object.fromEntries(filteredArray);
    if (updateLocalStorage) {
      this.storeFilteredTags(tags);
    }
    // no need to call fetchPrograms manually, as watcher on route will call it
    await this.updateURL();
  }

  storeFilteredTags(tags) {
    const tagsMap: Map<string, TagFilter[]> = new Map(Object.entries(tags));
    const storedFilters: TagGroupFilter[] = [];
    tagsMap.forEach((value, key: string) => {
      const storedFilter: TagGroupFilter = {
        id: key,
        name: key,
        tags: []
      };
      value.forEach((v: TagFilter) => {
        storedFilter.tags.push({
          label: v.label,
          id: v.id
        });
      });
      storedFilters.push(storedFilter);
    });
    FilterStorageService.set(FilterStorageService.VIRTUAL_WARD, JSON.stringify(storedFilters));
  }

  async fetchPrograms() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include: 'patient,assigned_organisational_unit,latest_measurement,discharged_review,tags,tagGroups',
        page: this.page,
        sort: this.sort,
        ...(this.search ? { 'filter[search]': this.search } : {}),
        ...(this.filteredTags || {})
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.copdProgramService.index(requestConfig)) as PaginatedResponse<CopdProgram[]>;
      this.request = null;
      this.rows = response.data;
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.patients = response.included?.filter((resource) => resource.type === 'patients') as unknown as Patient[];
      this.tags = response.included?.filter((resource) => resource.type === 'tags') as unknown as Array<Tag>;
      this.tagGroupResources = response.included?.filter((resource) => resource.type === 'tagGroups') as unknown as Array<TagGroup>;
      this.filtered = this.search.length > 0;
      this.organisationalUnits = response.included?.filter(
        (resource) => resource.type === 'organisationalUnits'
      ) as Organisation[];
      this.measurements = response.included?.filter(
        (resource) => resource.type === 'copdHomeMonitoringProgramMeasurements'
      ) as unknown as CopdProgramMeasurement[];
      this.reviews = response.included?.filter(
        (resource) => resource.type === 'copdHomeMonitoringProgramReviews'
      ) as unknown as CopdProgramReview[];
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.copd.programs-fetch-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async updateURL() {
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { 'filter[search]': this.search } : {}),
        ...(this.filteredTags || {})
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }
}
