import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "mb-10 text-xl font-semibold text-gray-900"
}
const _hoisted_4 = {
  key: 1,
  class: "self-center text-xl font-semibold text-gray-900"
}
const _hoisted_5 = { class: "flex items-center flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterGroup = _resolveComponent("FilterGroup")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_CopdPatientsTable = _resolveComponent("CopdPatientsTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.tagsFeatureEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.pageHeading), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex justify-between items-start border-b", {'pb-3':_ctx.tagsFeatureEnabled, 'pb-4':!_ctx.tagsFeatureEnabled}])
      }, [
        (_ctx.tagsFeatureEnabled && _ctx.preselectedTagGroupsLoaded)
          ? (_openBlock(), _createBlock(_component_FilterGroup, {
              key: 0,
              class: "flex-grow",
              "tag-groups": _ctx.tagGroups,
              "preselected-tag-groups": _ctx.preselectedTagGroups,
              "onUpdate:filteredTags": _ctx.filterTags
            }, null, 8, ["tag-groups", "preselected-tag-groups", "onUpdate:filteredTags"]))
          : (!_ctx.tagsFeatureEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.pageHeading), 1))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BaseTextInput, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            placeholder: _ctx.$t('platform.common.search'),
            icon: "search",
            "icon-position": "left",
            "icon-height": "16px",
            size: "small",
            "data-dusk": "program-search",
            onKeyup: _ctx.updateSearchDebounced
          }, null, 8, ["modelValue", "placeholder", "onKeyup"])
        ])
      ], 2),
      _createVNode(_component_CopdPatientsTable, {
        loading: _ctx.loading,
        page: _ctx.page,
        sort: _ctx.sort,
        rows: _ctx.rows,
        patients: _ctx.patients,
        tags: _ctx.tags,
        "tag-groups": _ctx.tagGroupResources,
        reviews: _ctx.reviews,
        "organisational-units": _ctx.organisationalUnits,
        measurements: _ctx.measurements,
        "organisation-id": _ctx.organisationId
      }, null, 8, ["loading", "page", "sort", "rows", "patients", "tags", "tag-groups", "reviews", "organisational-units", "measurements", "organisation-id"]),
      (_ctx.total > _ctx.perPage)
        ? (_openBlock(), _createBlock(_component_BasePagination, {
            key: 1,
            class: "mt-8",
            "model-value": _ctx.page,
            "page-size": _ctx.perPage,
            length: _ctx.total,
            "sm-pages-limit": 5,
            "md-pages-limit": 8,
            "items-name": _ctx.$t('custom.uhb.copd.pathway-episodes').toLowerCase(),
            "onUpdate:modelValue": _ctx.changePage
          }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}